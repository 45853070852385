import React from "react"
import Layout from "../../components/h/layout"
import { Random } from "react-animated-text"

const HalloweenIndexPage = () => (
  <Layout>
    <h1>
      <Random text="Happy Halloween!" effect="stretch" effectChange={2} />
    </h1>
    <p>Start at the front door...</p>
  </Layout>
)
export default HalloweenIndexPage
